import { gql } from '@apollo/client';

export const GET_ALL_ARTICLES = gql`
  query GetAllArticles {
    articles(sort: "date:desc") {
      data {
        id
        attributes {
          title
          date
          description
          img {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LAST_THREE_ARTICLES = gql`
  query GetLastThreeArticles {
    articles(sort: "date:desc", pagination: { limit: 3 }) {
      data {
        id
        attributes {
          title
          date
          description
          img {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ARTICLE = gql`
  query GetArticle($id: ID) {
    article(id: $id) {
      data {
        id
        attributes {
          title
          date
          description
          text
          img {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_OTHER_ARTICLES = gql`
  query GetOtherArticles($id: ID) {
    articles(
      sort: "date:desc"
      pagination: { limit: -1 }
      filters: { id: { notContains: $id } }
    ) {
      data {
        id
        attributes {
          title
          date
          description
          img {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_VACANCIES = gql`
  query GetAllVacancies {
    vacancies(sort: "createdAt:desc") {
      data {
        id
        attributes {
          title
        }
      }
    }
  }
`;

export const GET_VACANCY = gql`
  query GetVacancy($id: ID) {
    vacancy(id: $id) {
      data {
        id
        attributes {
          title
          experience
          employment
          responsibilities
          requirements
          offer
          descripition
          link
        }
      }
    }
  }
`;

export const GET_ALL__PURCHASES = gql`
  query GetAllPurchases($status: String, $sortOrder: [String]) {
    purchases(sort: $sortOrder, filters: { status: { eq: $status } }) {
      data {
        id
        attributes {
          number
          item
          date
          organizer
          status
          category
          start_deadline
          end_deadline
        }
      }
    }
  }
`;

export const GET_PURCHASE = gql`
  query GetPurchase($id: ID) {
    purchase(id: $id) {
      data {
        id
        attributes {
          number
          item
          date
          organizer
          status
          description
          start_deadline
          end_deadline
          mail
          documents {
            data {
              attributes {
                name
                size
                ext
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PREQUALIFICATION_DOCUMENTS = gql`
  query GetPrequalificationDocuments {
    prequalifications {
      data {
        id
        attributes {
          documents {
            data {
              attributes {
                name
                ext
                size
                url
              }
            }
          }
        }
      }
    }
  }
`;
