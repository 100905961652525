import {
  btnSecSm,
  Container,
  hover,
  Img,
  passed,
  primary,
  Row,
  tabFooterLg
} from 'Design';
import React from 'react';
import styled from 'styled-components';
import phone from 'images/icons/phone.svg';
import email from 'images/icons/email.svg';
import { BREAKPOINTS } from 'utils';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <FooterContainer>
      <StyledRow spaceBetween maxWidth={1180} alignCenter lgGap={1}>
        <Container mdHidden>
          <StyledRowNav alignCenter marginBottom={40} justifyContent={'center'}>
            <StyledLink to='/about-us'>О нас</StyledLink>
            <StyledLink to='/news'>нОВОСТИ</StyledLink>
            <StyledLink to='/purchases'>ЗАКУПКИ</StyledLink>
            <StyledLink to='/jobs'>вАКАНСИИ</StyledLink>
            <StyledLink to='/team'>кОМАНДА</StyledLink>
            <StyledLink to='/contacts'>кОНТАКТЫ</StyledLink>
          </StyledRowNav>
        </Container>

        <Container hidden mdVisible xsHidden mdMarginBottom={48}>
          <NavRow justifyContent='center' marginBottom={16}>
            <StyledLink to='/about-us'>О нас</StyledLink>
            <StyledLink to='/news'>нОВОСТИ</StyledLink>
            <StyledLink to='/purchases'>ЗАКУПКИ</StyledLink>
            <StyledLink to='/jobs'>вАКАНСИИ</StyledLink>
          </NavRow>
          <NavRow justifyContent='center'>
            <StyledLink to='/team'>кОМАНДА</StyledLink>
            <StyledLink to='/contacts'>кОНТАКТЫ</StyledLink>
          </NavRow>
        </Container>

        <Container hidden xsVisible marginBottom={48}>
          <NavRow direction={'column'} alignCenter>
            <StyledLink to='/about-us'>О нас</StyledLink>
            <StyledLink to='/news'>нОВОСТИ</StyledLink>
            <StyledLink to='/purchases'>ЗАКУПКИ</StyledLink>
            <StyledLink to='/jobs'>вАКАНСИИ</StyledLink>
            <StyledLink to='/team'>кОМАНДА</StyledLink>
            <StyledLink to='/contacts'>кОНТАКТЫ</StyledLink>
          </NavRow>
        </Container>

        <StyledRowContacts agignCenter gap={30} marginBottom={40}>
          <Row alignCenter mdMarginBottom={16} justifyContent='center'>
            <Img marginRight={12} src={phone} width={24} />
            <TelLink href='tel:89332221100'>+74958990908</TelLink>
          </Row>

          <Row alignCenter justifyContent='center'>
            <Img marginRight={12} src={email} width={24} />
            <EmailLink href='mailto:info@rador.org'>info@rador.org</EmailLink>
          </Row>
        </StyledRowContacts>
      </StyledRow>

      <WaterMarkContainer maxWidth={1180}>
        <Row spaceBetween>
          <AllRights>© 2022 • Все права защищены</AllRights>
          <Rad>Русские Автомобильные дороги</Rad>
        </Row>
      </WaterMarkContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  margin-top: 120px;
  padding: 80px 130px;
  width: 100%;
  border-top: 1px solid #d6d6d6;
  @media (max-width: ${BREAKPOINTS.lg}) {
    padding: 80px 97px;
  }
  @media (max-width: ${BREAKPOINTS.md}) {
    padding: 60px 53px;
  }
`;

const StyledRow = styled(Row)`
  margin: 0 auto;
  @media (max-width: 1290px) {
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: ${BREAKPOINTS.lg}) {
    justify-content: center;
  }
`;

const NavRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.md}) {
    gap: 32px;
  }
  @media (max-width: ${BREAKPOINTS.xs}) {
    gap: 16px;
  }
`;

const StyledRowContacts = styled(Row)`
  @media (max-width: ${BREAKPOINTS.md}) {
    flex-direction: column;
  }
`;

const StyledRowNav = styled(Row)`
  gap: 30px;
  @media (max-width: ${BREAKPOINTS.md}) {
    gap: 20px;
  }
`;

const StyledLink = styled(Link)`
  ${tabFooterLg}
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: ${hover};
  }
  &:active {
    color: ${passed};
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 14px;
    line-height: 19px;
  }
`;

const TelLink = styled.a`
  ${btnSecSm}
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: ${hover};
  }
  &:active {
    color: ${passed};
  }
`;

const EmailLink = styled(TelLink)``;

const WaterMarkContainer = styled(Container)`
  margin: 0 auto;
  @media (max-width: ${BREAKPOINTS.xl}) {
    & > div {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
`;

const AllRights = styled.p`
  ${btnSecSm}
  font-weight: 400;
  color: #afafaf;
`;

const Rad = styled(AllRights)`
  color: ${primary};
  @media (max-width: ${BREAKPOINTS.xl}) {
    margin-bottom: 16px;
  }
`;
