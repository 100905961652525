import {
  txtMd,
  Container,
  h4h,
  hover,
  Img,
  passed,
  Row,
  h5h,
  txtXm
} from 'Design';
import React from 'react';
import styled from 'styled-components';
import phone from 'images/icons/phone.svg';
import email from 'images/icons/email.svg';
import { BREAKPOINTS } from 'utils';

const contacts = [
  {
    title: 'Отдел управления делами и связей с общественностью',
    email: 'info@rador.org',
    tel: '+7 495 899-09-08',
    additional: '(доб. 5102)'
  },
  {
    title: 'Отдел централизованных закупок',
    email: 'dbkomaristov@rador.org',
    tel: '+7 495 899-09-08',
    additional: '(доб. 5114)'
  },
  {
    title: 'Отдел набора персонала и корпоративной политики',
    email: 'hr@rador.org',
    tel: '+7 495 899-09-08',
    additional: '(доб. 5105)'
  }
];

export const Contacts = (props) => {
  return (
    <Wrapper {...props} maxWidth={1180}>
      <StyledRow justifyContent={'center'}>
        {contacts.map((item, index) => (
          <StyledContainer key={index} maxWidth={380}>
            <Title>{item.title}</Title>

            <Row marginTop={24} justifyContent={'center'}>
              <Img marginRight={16} src={email} width={24} />
              <EmailLink href={`mailto:${item.email}`}>{item.email}</EmailLink>
            </Row>

            <Row marginTop={16} justifyContent={'center'}>
              <Img marginRight={16} src={phone} width={24} />
              <TelLink href={`tel:${item.tel}`}>
                {item.tel} {item.additional}
              </TelLink>
            </Row>
          </StyledContainer>
        ))}
      </StyledRow>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const StyledRow = styled(Row)`
  gap: 20px;
  @media (max-width: ${BREAKPOINTS.lg}) {
    column-gap: 150px;
    row-gap: 94px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    align-items: center;
    flex-direction: column;
    row-gap: 72px;
  }
`;

const StyledContainer = styled(Container)`
  width: 100%;
  & > * {
    text-align: center;
  }
`;

const Title = styled.h4`
  margin: 0 auto;
  max-width: 300px;
  ${h5h};
  font-weight: 600;
`;

const TelLink = styled.a`
  ${txtXm}
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: ${hover};
  }
  &:active {
    color: ${passed};
  }
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const EmailLink = styled(TelLink)``;
