import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Img, primary, Row, tabHeader, white } from 'Design';
import { BREAKPOINTS } from 'utils';
import close_icon from 'images/cancel.svg';
import logo from 'images/logo.png';
import phone from 'images/icons/phone.svg';
import email from 'images/icons/email.svg';

const LinkItem = ({ link, text, onClose }) => {
  return (
    <StyledLink to={link} onClick={onClose}>
      <LinkRow gap={21} smdGap={8}>
        <Subtitle>{text}</Subtitle>
      </LinkRow>
    </StyledLink>
  );
};

export const Menu = ({ opened = false, onClose }) => {
  return (
    <MenuWrapper opened={opened}>
      <Scrolled>
        <Link to='/' onClick={onClose}>
          <Logo src={logo} />
        </Link>
        <CloseIcon onClick={onClose} src={close_icon} alt={''} />

        <LinksWrapper>
          <LinkItem link='/' onClose={onClose} text='Главная' />
          <LinkItem link='/about-us' onClose={onClose} text='О нас' />
          <LinkItem link='/news' onClose={onClose} text='Новости' />
          <LinkItem link='/purchases' onClose={onClose} text='Закупки' />
          <LinkItem link='/jobs' onClose={onClose} text='Вакансии' />
          <LinkItem link='/team' onClose={onClose} text='Команда' />
          <LinkItem link='/contacts' onClose={onClose} text='Контакты' />
        </LinksWrapper>

        <SocialLinks>
          <Row alignCenter marginBottom={16}>
            <Img marginRight={12} src={phone} width={24} />
            <TelLink href='tel:89332221100'>+7 495 899-09-08</TelLink>
          </Row>
          <Row alignCenter>
            <Img marginRight={12} src={email} width={24} />
            <EmailLink href='mailto:info@rador.org'>info@rador.org</EmailLink>
          </Row>
        </SocialLinks>
      </Scrolled>
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  ${(p) => `
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  transform : ${p.opened ? 'unset' : 'translateX(100%)'};
  transition: .2s;
  background: ${white};
  color: ${primary};
  overflow: auto;
  z-index: 100;
  display: none;
  
  @media (max-width: ${BREAKPOINTS.lg}) {
    display: block;
  }
`}
`;
const Scrolled = styled.div`
  min-height: 100%;
  padding: 100px 55px 140px 55px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.sm}) {
    padding: 100px 16px 140px;
  }
`;

const Logo = styled(Img)`
  position: absolute;
  top: 10px;
  left: 16px;
  width: 100px;
`;

const CloseIcon = styled(Img)`
  position: absolute;
  right: 17px;
  top: 17px;
  width: 36px;
`;

const LinksWrapper = styled.div``;

const StyledLink = styled(Link)`
  height: 49px;
  display: block;
  color: #ffffff;
  text-decoration: unset;
  border-bottom: 1px solid #ffffff;
  padding: 0 3px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  :last-child {
    border: none;
  }
  :active {
    opacity: 0.6;
  }
`;
const LinkRow = styled(Row)`
  height: 100%;
  align-items: center;
  flex-wrap: nowrap;
`;
const Subtitle = styled.p`
  ${tabHeader}
`;

const SocialLinks = styled.div`
  position: absolute;
  bottom: 40px;
  left: 55px;
  @media (max-width: ${BREAKPOINTS.sm}) {
    left: 16px;
  }
`;

const TelLink = styled.a`
  ${tabHeader}
`;

const EmailLink = styled(TelLink)``;
