import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { white, txtLg, h2h, h5h, txtSm } from 'Design';
import { BREAKPOINTS } from 'utils';

export const MainSlider = ({
  videoId = '',
  image,
  center = false,
  title = '',
  body = ''
}) => {
  if (videoId) {
    return (
      <PlayerWr center={center}>
        <Player
          style={{ width: '100vw', height: '100vh' }}
          url={`${process.env.PUBLIC_URL}/ver7.mp4`}
          width='100vw'
          height='100vh'
          playing={true}
          controls={false}
          loop={true}
          responsive={true}
          volume={0}
          muted={true}
          config={{
            file: {
              attributes: {
                autoplay: 'true',
                crossOrigin: 'true',
                playsInline: 'true'
              }
            }
          }}
        />
        <TextBox center={center}>
          <h1>{title}</h1>
          <p>{body}</p>
        </TextBox>
      </PlayerWr>
    );
  }
  return (
    <Wr center={center} image={image}>
      <TextBox center={center}>
        <h1>{title}</h1>
        <p>{body}</p>
      </TextBox>
    </Wr>
  );
};

const Wr = styled.div`
  position: relative;
  width: 100%;
  padding: 0 130px;
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.center ? 'center' : 'flex-start')};
  height: 705px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${(p) => p.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: ${BREAKPOINTS.md}) {
    padding: 0 70px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 521px;
    padding: 0 16px;
  }
`;

const PlayerWr = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 112px);
  padding: 0 130px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: ${(p) => (p.center ? 'center' : 'flex-start')};
  @media (max-width: ${BREAKPOINTS.xl}) {
    height: calc(100vh - 80px);
  }
  @media (max-width: ${BREAKPOINTS.md}) {
    padding: 0 70px;
    height: calc(100vh - 68px);
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    padding: 0 16px;
  }
`;

const Player = styled(ReactPlayer)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  & video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
    @media (min-aspect-ratio: 16/10) {
      height: 56.25vw;
    }
    @media (max-aspect-ratio: 16/10) {
      width: 177.78vh;
    }
  }
`;

const TextBox = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.center ? 'center' : 'flex-start')};
  gap: 16px;
  max-width: 858px;
  text-align: ${(p) => (p.center ? 'center' : 'left')};

  & h1 {
    ${h2h};
    color: ${white};
    @media (max-width: ${BREAKPOINTS.md}) {
      ${h2h};
    }
    @media (max-width: ${BREAKPOINTS.sm}) {
      ${h5h};
    }
  }
  & p {
    ${txtLg};
    color: ${white};
    @media (max-width: ${BREAKPOINTS.sm}) {
      ${txtSm};
    }
  }
`;
