import { useQuery } from '@apollo/client';
import { GET_OTHER_ARTICLES } from 'api/queries';
import { Date, PrimaryButton, Title } from 'components';
import { Container, h5h, Img, Row, tabHeader } from 'Design';
import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BREAKPOINTS, getImageUrl } from 'utils';

export const OtherNews = () => {
  const [limit, setLimit] = useState(2);

  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_OTHER_ARTICLES, {
    variables: { id: id }
  });

  const dataQuery = useMemo(() => {
    if (data) {
      return data.articles?.data;
    }

    return null;
  }, [data]);

  if (loading) {
    return <p>Loading...</p>;
  } else if (error === []) {
    return <p>Something went wrong...</p>;
  }

  const showMore = () => {
    setLimit((prev) => prev + 5);
  };

  if (dataQuery && !error) {
    return dataQuery.length === 0 ? (
      ''
    ) : (
      <>
        <OtherNewsTitle>Другие новости</OtherNewsTitle>
        {dataQuery.slice(0, limit).map((item, index) => (
          <Link to={`/news/${item.id}`}>
            <StyledRow nowrap key={index} alignCenter marginBottom={35}>
              <StyledImg
                src={getImageUrl(item.attributes.img?.data?.attributes?.url)}
              />

              <Container>
                <Container marginBottom={8}>
                  <Date date={item.attributes.date} />
                </Container>

                <ContainerTitle maxWidth={240} xxlMaxWidth={'432px'}>
                  <Title
                    small
                    title={
                      item.attributes.title.length < 50
                        ? item.attributes.title
                        : `${item.attributes.title?.substr(0, 50)}...`
                    }
                  />
                  <Container hidden xxlVisible marginTop={12}>
                    {item.attributes.description && (
                      <Description>
                        {item.attributes.description.length < 100
                          ? item.attributes.description
                          : `${item.attributes.description?.substr(0, 100)}...`}
                      </Description>
                    )}
                  </Container>
                </ContainerTitle>
              </Container>
            </StyledRow>
          </Link>
        ))}
        {limit > dataQuery.length ? null : (
          <StyledButton>
            <PrimaryButton
              onClick={() => showMore()}
              title='Смотреть еще'
              large
            />
          </StyledButton>
        )}
      </>
    );
  }
  return <p>Something went wrong...</p>;
};

const OtherNewsTitle = styled.p`
  margin-bottom: 48px;
  ${h5h}
  @media (max-width: ${BREAKPOINTS.xxl}) {
    font-size: 30px;
    line-height: 36px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const StyledRow = styled(Row)`
  gap: 20px;
  @media (max-width: ${BREAKPOINTS.sm}) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
`;

const ContainerTitle = styled(Container)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    max-width: unset;
  }
`;

const StyledImg = styled(Img)`
  width: 100%;
  max-width: 132px;
  height: 124px;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.xxl}) {
    max-width: 252px;
    height: 188px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    max-width: unset;
  }
`;

const StyledButton = styled(Row)`
  @media (max-width: ${BREAKPOINTS.xxl}) {
    justify-content: center;
  }
`;

const Description = styled.p`
  ${tabHeader}
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 22px;
  }
`;
