import React from 'react';
import styled from 'styled-components';
import { Row, black, passed, tabHeader } from 'Design';
import { BREAKPOINTS, formatDateFromStrapi } from 'utils';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

export const PurchaseItem = ({
  number,
  item,
  date,
  organizer,
  status,
  id,
  start_deadline,
  end_deadline
}) => {
  return (
    <Link to={`/purchase/${id}`}>
      <StyledRow paddingY={24} nowrap>
        <Text blue noMargin width={'144px'}>
          {number}
        </Text>

        <Text width={'196px'} lgMarginLeft={24}>
          {item}
        </Text>

        <Text width={'186px'} marginLeft={'22px'} lgMarginLeft={32} smHidden>
          {formatDateFromStrapi(date)}
        </Text>

        <Text width={'242px'} lgHidden>
          {`от ${formatDateFromStrapi(
            start_deadline
          )} до ${formatDateFromStrapi(end_deadline)}`}
        </Text>

        <Text width={'176px'} lgHidden>
          {organizer}
        </Text>

        <Text width={'80px'} smallMargin lgMarginLeft={32} smHidden>
          {status === 'active' ? 'Активен' : 'В архиве'}
        </Text>
      </StyledRow>
    </Link>
  );
};

const StyledRow = styled(Row)`
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const Text = styled.p`
  margin-left: ${(p) =>
    p.noMargin
      ? 'unset'
      : p.smallMargin
      ? '14px'
      : p.marginLeft
      ? p.marginLeft
      : '40px'};
  max-width: ${(p) => (p.width ? p.width : 'unset')};
  width: 100%;
  ${tabHeader};
  color: ${(p) => (p.blue ? passed : black)};
  ${(p) =>
    p.blue
      ? `
  text-decoration-color: #2f6ae3;
  text-decoration-line: underline;`
      : ''}

  @media (max-width: ${BREAKPOINTS.lg}) {
    ${(p) => (p.lgHidden ? 'display: none;' : '')};
    ${(p) => (p.lgMarginLeft ? `margin-left:${p.lgMarginLeft}px` : '')};
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    ${(p) => (p.smHidden ? 'display: none;' : '')};
    font-size: 16px;
    line-height: 22px;
  }
`;
