import React from 'react';
import styled from 'styled-components';
import './CustomDatePicker.css';
import { DatePicker } from 'antd';
import { BREAKPOINTS } from 'utils';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import calendarSvg from 'images/icons/calendar.svg';
import { Img } from 'Design';

const { RangePicker } = DatePicker;

const CalendarIcon = () => {
  return <Img src={calendarSvg} />;
};

export const CustomDatePicker = ({ setDate }) => {
  return (
    <CustomRangePicker
      format={'YYYY-MM-DD'}
      locale={locale}
      separator={<CalendarIcon />}
      suffixIcon={<CalendarIcon />}
      placeholder={['От', 'До']}
      onChange={(value) => {
        if (value) {
          const date = {
            startDate: value[0].$d.toISOString().split('T').shift(),
            endDate: value[1].$d.toISOString().split('T').shift()
          };
          setDate(date);
        } else setDate(value);
      }}
    />
  );
};

const CustomRangePicker = styled(RangePicker)`
  max-width: 520px;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.lg}) {
    max-width: 400px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    max-width: unset;
  }
`;
